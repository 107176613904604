import axios from "axios";
import store from "@/store/index";
import Vue from "vue";

axios.defaults.baseURL = "https://gvsfarmapi.gvssystem.com.br";
//axios.defaults.baseURL =
// "http://localhost:81" || process.env.apiUrl || "";
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    store.dispatch("showLoader");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.dispatch("hideLoader");
    const resp = response.config;

    if (resp.config) {
      if (resp.config.responseToast) {
        Vue.$toast.success(resp.config.responseToast.title);
      }
    }

    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 419) {
      store.commit("SET_LOGOUT");
    }
    if (
      error.response.status == 404 ||
      error.response.status == 500 ||
      error.response.status == 0
    ) {
      Vue.$toast.error("Houve um erro , fale com o suporte !");
    }

    if (error.response.status === 422) {
      let errors = error.response.data.errors;

      Object.entries(errors).forEach(([key, value]) => {
        Vue.$toast.error(value[0]);
        console.log(key);
      });
    }

    store.dispatch("hideLoader");
    return Promise.reject(error);
  }
);
